<template>
  <div elevation="0">
    <v-container>
      <div>
        <h3 class="text-xl">
          <slot name="title"></slot>
        </h3>
      </div>

      <div>
        <div class="mt-3">
          <slot name="subtitle"></slot>
        </div>

        <bar v-if="hasBar" :type="barType">
          <template v-slot:label>
            <slot name="text-label"></slot>
          </template>
          <template v-slot:value>
            <slot name="text-value"></slot>
          </template>
        </bar>
      </div>

      <div v-if="hasTextarea">
        <v-textarea
          v-model="textarea"
          :label="textareaLabel"
          outlined
        ></v-textarea>
      </div>
    </v-container>

    <v-container>
      <v-btn depressed @click="clickBtn" color="primary" width="100%">
        <slot name="btn-text">確認</slot>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    bar: () => import("@/modules/base/components/bar/bar.vue"),
  },
  data: () => ({
    textarea: null,
  }),
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    hasBar: {
      type: Boolean,
      default: true,
    },
    barType: {
      type: String,
      default: "primary",
      validator(value) {
        return ["primary", "secondary", "error", "outlined"].includes(value);
      },
    },
    hasTextarea: {
      type: Boolean,
      default: false,
    },
    textareaLabel: String,
  },
  computed: {},
  methods: {
    clickBtn() {
      this.action(this.textarea);
    },
  },
};
</script>